<template>
  <div class="homePage" id="home">
    <swiper :navigation="false" @swiper="onSwiper" :modules="modules" class="random mySwiper" id="swiper" :loop="true"
      :zoom="true" :zoomMax="3" :pagination="true" :preloadImages="false" :virtual="{ slides: files }"
      v-if="randomFilm && randomFilm.length > 0">
      <swiper-slide class="randomContainer swiper-slide" v-for="element in randomFilm" v-bind:key="element.id" v-cloak>
        <img :src="element.backgroundURL" alt="Image de fond" loading="lazy">
        <div class="poster" data-aos="fade-right" data-aos-anchor-placement="center-bottom" data-aos-duration="500">
          <img :src="element.posterURL" @click="seenMovie(element.id, element.name)" alt="Affiche du film"
            loading="lazy" onerror="this.src='https\://api.heavenflix.fr/images/error.webp'">
        </div>
      </swiper-slide>
      <swiper-pagination class="swiper-pagination-custom" :bulletClass="customBulletClass"
        :bulletActiveClass="customBulletActiveClass"></swiper-pagination>
    </swiper>
    <div class="noFilmContainer" v-else>
      <p id="loaderText">Chargement..</p>
    </div>
    <div class="goTop" v-show="scrollDistance > 775">
      <button :onclick="() => goTop()" title="Remonter" id="topButton"><svg xmlns="http://www.w3.org/2000/svg"
          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-up">
          <polyline points="17 11 12 6 7 11"></polyline>
          <polyline points="17 18 12 13 7 18"></polyline>
        </svg></button>
    </div>
    <div class="displayer" id="displayer" v-if="selectedMovie">
      <DisplayerMovie :movie="selectedMovie" :isAdmin="isAdmin" @close-modal="closeSeenMovie()"
        @change-movie="seenMovie($event)" />
    </div>
    <NavBar />
    <NotificationsContainer />
    <div class="collectionsCarrouselContainer" v-if="collections != undefined && wantCarrousel">
      <swiper :navigation="false" :modules="modules" class="collectionsCarrousel mySwiper" id="swiper" :zoom="true"
        :zoomMax="3" :preloadImages="false" v-if="collections != undefined" loop :centeredSlides="true"
        slidesPerView="auto" spaceBetween="20">
        <swiper-slide class="collectionsCarrouselCard swiper-slide" v-for="col in  collections " :key="col.id"
          :id="col.id" @click="getAllByCollection(col.name)">
          <img :src="col.posterURL" alt="Affiche de la collection" loading="lazy">
          <h2>{{ col.name }}</h2>
        </swiper-slide>
      </swiper>
    </div>
    <div class="orderContainer" id="top">
      <div class="order">
        <select name="triage" id="triage" v-once>
          <option selected>Trier</option>
          <option value="name.asc">De A à Z</option>
          <option value="name.desc">De Z à A</option>
          <option value="duration.desc">Les plus longs</option>
          <option value="duration.asc">Les plus courts</option>
          <option value="id.desc">Les derniers ajouts</option>
          <option value="id.asc">Les premiers ajouts</option>
          <option value="annedesortie.desc">Les plus récents</option>
          <option value="annedesortie.asc">Les plus anciens</option>
        </select>
        <select name="quality" id="quality">
          <option selected>Qualité</option>
          <option value="4K">4K (UHD)</option>
          <option value="1080p">1080p (HD)</option>
          <option value="720p">720p (DVD)</option>
          <option value="480p">480p (SD)</option>
          <option value="360p">360p (SD)</option>
          <option value="240p">240p (SD)</option>
        </select>
        <select name="category" id="categories" :onchange="() => getAllByCategory()">
          <option :value="undefined" selected>Catégorie</option>
          <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
          </option>
        </select>
        <select name="collection" id="collections" :onchange="() => getAllByCollection()" v-if="wantCarrousel == false">
          <option :value="undefined" selected>Collection</option>
          <option v-bind:key="element.id" v-for="element in collections" :value="element.name">{{ element.name }}
          </option>
        </select>
        <div class="layout" v-if="wantWideCard == true">
          <button class="layoutBtn" title="Affichage par poster" @click="changeCardStyle"><svg
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-columns">
              <path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18">
              </path>
            </svg></button>
        </div>
        <div class="layout" v-else-if="wantWideCard == false">
          <button class="layoutBtn" title="Affichage étendu" @click="changeCardStyle"><svg
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-grid">
              <rect x="3" y="3" width="7" height="7"></rect>
              <rect x="14" y="3" width="7" height="7"></rect>
              <rect x="14" y="14" width="7" height="7"></rect>
              <rect x="3" y="14" width="7" height="7"></rect>
            </svg></button>
        </div>
        <div class="resetSortContainer" v-if="hasUrlParams">
          <button class="resetSort" @click="cleaningURLParams(true)" title="Supprimer les filtres" v-if="!isUserOnMobile"><i class="fa-solid fa-xmark"></i></button>
          <button class="resetSort mobile" @click="cleaningURLParams(true)" title="Supprimer les filtres" v-else>Supprimer les filtres</button>
        </div> 
      </div>
      <div class="searchBarContainer">
        <div class="searchBar">
          <input type="search" id="searchBar" list="suggestions" placeholder="Rechercher.." @input="getSearchList()" @change="getMoviesFromDynamicSort">
          <datalist id="suggestions">
            <option v-for="suggestion in suggestions" v-bind:key="suggestion" :value="suggestion" />
          </datalist>
          <svg @click="getMoviesFromDynamicSort" alt="Icone de recherche" xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-search" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
            <path d="M21 21l-6 -6" />
          </svg>
        </div>
      </div>
    </div>
    <!--<AdviceContainer />-->
    <div class="noResult" v-if="!films.length">
      <img src="@/assets/loader.svg" id="loader" alt="Loader">
      <p id="loaderText">Chargement..</p>
    </div>
    <swiper class="watchedSection" v-if="watchedMovies && watchedMovies.length" :navigation="false" :modules="modules"
      id="swiperWatched" slidesPerView="auto" spaceBetween="40px">
      <swiper-slide v-for="item in watchedMovies" v-bind:key="item.id" class="watchedSlider">
        <CardWatchedMovieContainer :movie="item" :watchtime="item.watchtime_id" v-cloak @play-movie="goToMovie(item.id)"
          @delete-watchtime="handleDeleteWatchtime" :type="'movie'" />
      </swiper-slide>
    </swiper>
    <!--<div class="xMasContainer" v-if="films.length">
      <div class="xMasButton" @click="addURLParams('co', 'Noel', true)">
        <img src="https://cdn.pixabay.com/photo/2021/11/19/15/21/christmas-6809681_1280.png" alt="Image de Noël">
        <p>🔔 Notre séléction pour Noël 🔔</p>
      </div>
    </div>-->
    <div class="filmsSection" v-if="films.length && wantWideCards == true">
      <CardMovieContainer v-for="item in films.slice(0, limit)" v-bind:key="item.id" :movie="item" data-aos="fade-up"
        data-aos-anchor-placement="center" data-aos-duration="2000" v-cloak @click="seenMovie(item.id)"
        :type="'movie'" />
    </div>
    <div class="filmsSection" v-else-if="films.length && wantWideCards == false">
      <CardMoviePosterContainer v-for="item in films.slice(0, limit)" v-bind:key="item.id" :movie="item"
        data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000" v-cloak
        @click="seenMovie(item.id)" :type="'movie'" />
    </div>
    <div class="loaderWrapper">
      <div class="loader" v-if="limit < films.length">
        <button class="loaderBtn" @click="loadMore">Charger d'avantage <i
            class="fa-solid fa-arrow-turn-down"></i></button>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>

<script>
/* eslint-disable */
import AOS from 'aos';
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
//SwiperJS
import { Swiper, SwiperSlide } from 'swiper/vue';
// importez les modules Swiper requis
import { Navigation, Pagination, Zoom, Virtual } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import NavBar from './NavBarContainer.vue';
import DisplayerMovie from './DisplayerMovieContainer.vue';
import CardMovieContainer from './CardMovieContainer.vue';
import CardWatchedMovieContainer from './CardWatchedMovieContainer.vue';
import CardMoviePosterContainer from './CardMoviePosterContainer.vue';
import FooterContainer from './FooterContainer.vue';
//import AdviceContainer from './AdviceContainer.vue';
import NotificationsContainer from './NotificationsContainer.vue';

import io from 'socket.io-client';

export default {
  name: 'HomeContainer',
  components: {
    Swiper,
    SwiperSlide,
    NavBar,
    DisplayerMovie,
    CardMovieContainer,
    CardWatchedMovieContainer,
    CardMoviePosterContainer,
    FooterContainer,
    //AdviceContainer,
    NotificationsContainer,
  },
  data() {
    return {
      selectedMovie: undefined,
      films: [],
      categories: undefined,
      collections: undefined,
      randomFilm: undefined,
      recommendationFilms: undefined,
      recommendationFilms2: undefined,
      recommendationFilmsCollection: undefined,
      recommendationFilmsCollection2: undefined,
      isAdmin: 0,
      limit: localStorage.getItem('limit'),
      link: "https://api.heavenflix.fr",
      codes: [],
      loadingTime: 3000,
      scrollDistance: 0,
      listOfUser: undefined,
      wantWideCards: JSON.parse(localStorage.getItem('wantWideCards')) == true ? true : false,
      bandeAnnonce: undefined,
      wantCarrousel: JSON.parse(localStorage.getItem('wantCarrousel')),
      API_KEY: 'AIzaSyBliI_rrX6jueTuKE5M2glCprJ6UZojs4g',
      hosterLink: 'https://darkibox.com/',
      userToken: localStorage.getItem('token'),
      posterPlayer: 'https://api.heavenflix.fr/images/messages/video_loading.png',
      streamingLink: 'https://server.heavenflix.fr/videos/movies/dunkirk_2017.mp4',
      streamingReady: false,
      watchedMovies: [],
      suggestions: [],
      hasUrlParams: false,
      isUserOnMobile: false,
      isInitialLoad: true,
    }
  },
  setup() {
    const onSwiper = (Swiper) => {

    };
    const onSlideChange = (swiper) => {
      /*const slides = document.getElementsByClassName('swiper-slide');
      let currentIndex = swiper.activeIndex;*/
    };
    const toast = () => {
      createToast('Wow, easy')
    }
    return {
      toast,
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Zoom, Virtual],
    };
  },
  methods: {
    async getSearchList() {
      const searchInput = document.getElementById('searchBar');
      const searchValue = searchInput.value;
      this.suggestions = [];
      if(searchValue && searchValue.length < 1) return this.cleaningURLParams(true);;
      if (searchValue.length < 2) return;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovieNames/' + searchValue, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) {
          return console.log(data.msg);
        }
        data.forEach(element => {
          this.suggestions.push(element.name);
        });
      })
    },
    handleDeleteWatchtime: async function handleDeleteWatchtime(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/watchtime/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (!data || !data.code || data.code != 200) return console.log('Erreur lors de la suppression du watchtime');
        this.showNotification('success', 'Le film a été retiré de votre liste.', 3000);
        this.getWatchedMovies();
      })
    },
    goToMovie(id) {
      this.$router.push('/player/movie/' + id + '/noseason/noindex');
    },
    getWatchedMovies: async function getWatchedMovies() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getWatchedMovies/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.watchedMovies = [];
        if (data.length > 0) {
          data.forEach(element => {
            element.cat = JSON.parse(element.cat);
            if(element.cat[0] == element.cat[1]) {
              element.cat = element.cat[0];
            } else {
              element.cat = element.cat.join(' & ');
            }
            this.watchedMovies.push(element);
          });
        }
      })
    },
    changeCardStyle: async function changeCardStyle() {
      this.wantWideCards = !this.wantWideCards;
    },
    checkUserEmail: async function checkUserEmail() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/checkUserEmail/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        if (data[0].email == null) {
          this.showNotification('danger', 'Veuillez renseigner votre adresse email dans les paramètres.', 10000);
        }
      })
    },
    disableMovie: async function disableMovie(value, name) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/disableMovie/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.closeSeenMovie();
        this.showNotification('success', 'Le film ' + name + ' a été désactivé.', 3000);
        this.getAllRandom();
        this.randomMovie();
      })
    },
    getListOfUser: async function getListOfUser() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getListFromUserId/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.listOfUser = data;
      })
    },
    removeWatchLater(id, name) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      fetch(this.link + '/deleteFromWatchlater/' + localStorage.getItem('userId') + '/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.getListOfUser();
        this.showNotification('success', name + ' a été retiré de votre liste', 3000);
      })
    },
    isInWatchLaterList(id) {
      let isFound = false;
      const self = this.listOfUser;
      self.forEach(element => {
        if (element.id == id) isFound = true;
      });
      return isFound;
    },
    showNotification(type, text, duration) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: duration,
      })
    },
    addWatchLater: async function addWathchLater(id, isMovie, name) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem('userId'),
          article_id: id,
          isMovie: isMovie,
        }),
      };
      await fetch(this.link + '/addToWatchlater', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        }
        this.showNotification('success', name + ' a été ajouté à votre liste.', 3000);
        this.getListOfUser()
      });
    },
    resetLoading: async function resetLoading() {
      this.limit = localStorage.getItem('limit');
    },
    loadMore: async function loadMore() {
      this.limit = parseInt(this.limit) + parseInt(localStorage.getItem('limit'));
    },
    closeSeenMovie: async function closeSeenMovie() {
      document.body.style.overflow = 'auto';
      this.selectedMovie = undefined;
    },
    randomMovie: async function randomMovie() {
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovieRandom', requestOptions).then((res) => res.json())
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.randomFilm = [];
          data.forEach(element => {
            element.cat = JSON.parse(element.cat);
            this.randomFilm.push(element);
          });
        })
    },
    seenMovie: async function seenMovie(value, name) {
      this.selectedMovie = undefined;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovie/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        data[0].cat = JSON.parse(data[0].cat);
        if (data[0].collection) data[0].collection = JSON.parse(data[0].collection);
        this.selectedMovie = data[0];
      })
      document.body.style.overflow = 'hidden';
    },
    goTop: async function goTop() {
      let anchor = document.getElementById('top');
      let top = anchor.offsetTop;
      window.scrollTo(0, top - 100);
    },
    request: async function request(id) {
      localStorage.setItem('request', id);
      this.$router.push('/request');
    },
    deleteMovie: async function deleteMovie(value) {
      let text = "Etes vous sur de vouloir supprimer ce film ?";
      if (confirm(text) == false) return alert('Vous avez annulé la suppresion du film.');
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/deleteMovie/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        location.reload();
      })
    },
    editMovie: async function editMovie(value) {
      localStorage.setItem('edit', value);
      localStorage.setItem('editType', 'movie');
      this.$router.push('/edit');
    },
    getAllRandom: async function getAllRandom() {
      this.resetLoader();
      setTimeout(() => this.checkerLoading(), this.loadingTime);
      this.films = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const moviePromise = await fetch(this.link + '/allMoviesRandom/' + localStorage.getItem('limit') + 1, requestOptions).then((res) => res.json());
      const movies = await moviePromise;
      movies.forEach(element => {
        element.cat = JSON.parse(element.cat);
        element.cat = element.cat.join(' & ');
        this.films.push(element);
      });
    },
    getCategories: async function getCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
      })
    },
    getCollections: async function getCollections() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCollections', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.collections = data;
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      if (!localStorage.getItem('userId')) {
        this.showNotification('info', 'Vous devez être connecté pour accéder à cette page.', 3000);
        setTimeout(() => {
          this.disconnect();
        }, 3000);
      }
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
    disconnect: async function disconnect() {
      localStorage.clear();
      this.$router.push('/login');
    },
    checkerLoading: async function checkerLoading() {
      let loader = document.getElementById('loader');
      let loaderText = document.getElementById('loaderText');
      if (!this.films || this.films && this.films.length < 1) {
        loader.style.display = "none";
        loaderText.innerHTML = "Aucun résultat";
      }
    },
    resetLoader: async function resetLoader() {
      let loader = document.getElementById('loader');
      let loaderText = document.getElementById('loaderText');
      if (loader) loader.style.display = "block";
      if (loaderText) loaderText.innerHTML = "Chargement...";
    },
    displayNoResult: async function displayNoResult() {
      let loader = document.getElementById('loader');
      let loaderText = document.getElementById('loaderText');
      if (loader) loader.style.display = "none";
      if (loaderText) loaderText.innerHTML = "Aucun résultat";
    },
    updateScrollDistance() {
      // Mettez à jour scrollDistance avec la distance entre le haut de la page et la position actuelle de l'utilisateur
      this.scrollDistance = window.scrollY;
    },
    cleaningURLParams(refreshing) {
      const urlParams = new URLSearchParams(window.location.search);
      // Supprimer les paramètres de recherche de l'URL actuelle
      urlParams.delete('s');
      // Supprimer les paramètres de tri de l'URL actuelle
      urlParams.delete('t');
      // Supprimer les paramètres de qualité de l'URL actuelle
      urlParams.delete('q');
      // Supprimer les paramètres de catégorie de l'URL actuelle
      urlParams.delete('c');
      // Supprimer les paramètres de collection de l'URL actuelle
      urlParams.delete('co');
      const newUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
      window.history.replaceState(history.state, '', newUrl);
      this.$router.replace(newUrl);
      if(refreshing) {
        this.resetSelects();
        this.getMoviesFromDynamicSort();
        this.$router.push(newUrl);
      }
      this.hasUrlParams = false;
    },
    addURLParams(type, value, needRefresh) {
      const urlParams = new URLSearchParams(window.location.search);
      this.hasUrlParams = true;
      if(type == 's') this.cleaningURLParams(false);
      if(urlParams.has('s') && type != 's') urlParams.delete('s');
      if(type != 's' && type != 't' && type != 'q' && type != 'c' && type != 'co') return console.error('Type de paramètre invalide');
      urlParams.set(type, value);
      const newUrl = window.location.pathname + '?' + urlParams.toString();
      window.history.replaceState(history.state, '', newUrl);
      this.$router.replace(newUrl);
      this.$router.push(newUrl);
      if(needRefresh) this.getMoviesFromDynamicSort();
    },
    deleteURLParam(type) {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has(type)) return;
      urlParams.delete(type);
      const newUrl = window.location.pathname + '?' + urlParams.toString();
      window.history.replaceState(history.state, '', newUrl);
      this.$router.replace(newUrl);
      this.$router.push(newUrl);
    },
    setParamsToSelect() {
      const urlParams = new URLSearchParams(window.location.search);
      const selectElement = document.querySelector('#triage');
      const qualityBar = document.getElementById('quality');
      const searchBar = document.getElementById('searchBar');
      const categoryBar = document.getElementById('categories');
      const collectionBar = document.getElementById('collections');
      if (urlParams.has('s')) searchBar.value = urlParams.get('s');
      if (urlParams.has('t')) selectElement.value = urlParams.get('t');
      if (urlParams.has('q')) qualityBar.value = urlParams.get('q');
      if (urlParams.has('c')) categoryBar.value = urlParams.get('c');
      if (urlParams.has('co')) collectionBar.value = urlParams.get('co');
    },
    enableSelects() {
      const selectElement = document.querySelector('#triage');
      const qualityBar = document.getElementById('quality');
      const categoryBar = document.getElementById('categories');
      const collectionBar = document.getElementById('collections');
      if(selectElement) selectElement.disabled = false;
      if(qualityBar) qualityBar.disabled = false;
      if(categoryBar) categoryBar.disabled = false;
      if(collectionBar) collectionBar.disabled = false;
    },
    disableSelects() {
      const selectElement = document.querySelector('#triage');
      const qualityBar = document.getElementById('quality');
      const categoryBar = document.getElementById('categories');
      const collectionBar = document.getElementById('collections');
      if(selectElement) selectElement.disabled = true;
      if(qualityBar) qualityBar.disabled = true;
      if(categoryBar) categoryBar.disabled = true;
      if(collectionBar) collectionBar.disabled = true;
    },
    resetSelects() {
      const selectElement = document.querySelector('#triage');
      const qualityBar = document.getElementById('quality');
      const searchBar = document.getElementById('searchBar');
      const categoryBar = document.getElementById('categories');
      const collectionBar = document.getElementById('collections');
      selectElement.value = 'Trier';
      qualityBar.value = 'Qualité';
      searchBar.value = '';
      categoryBar.value = 'Catégorie';
      collectionBar.value = 'Collection';
      this.enableSelects();
    },
    async getMoviesFromDynamicSort() {
      // Récupérer les paramètres de l'URL actuelle
      const urlParams = new URLSearchParams(window.location.search);
      // Récupérer le paramètre de tri de l'URL actuelle
      const sort = urlParams.get('t');
      // Récupérer le paramètre de qualité de l'URL actuelle
      const quality = urlParams.get('q');
      // Récupérer le paramètre de catégorie de l'URL actuelle
      const category = urlParams.get('c');
      // Récupérer le paramètre de collection de l'URL actuelle
      const collection = urlParams.get('co');
      const searchBar = document.getElementById('searchBar');
      const searchBarValue = searchBar ? searchBar.value : undefined;

      if((searchBarValue == undefined || searchBarValue.length == 0) && !this.isInitialLoad) {
        urlParams.delete('s');
        window.history.replaceState({}, '', `${location.pathname}${urlParams ? '?' + urlParams.toString() : ''}`);
        this.resetSelects();
      }

      let search = searchBarValue ? searchBarValue : urlParams.get('s');
      if (search && search.length > 0) {
        if(category) this.deleteURLParam('c');
        if(sort) this.deleteURLParam('t');
        if(quality) this.deleteURLParam('q');
        if(collection) this.deleteURLParam('co');
        if(searchBar && searchBar.value.length == 0) {
          this.deleteURLParam('s');
          this.enableSelects();
        }
        this.addURLParams('s', search, false);
        this.disableSelects();
      } else {
        this.deleteURLParam('s');
        this.enableSelects();
      }

      // Si il n'y a pas de paramètre de tri dans l'URL actuelle
      if (!sort && !quality && !category && !collection && !search) {
        this.getAllRandom();
        this.enableSelects();
        return this.hasUrlParams = false;
      }

      this.hasUrlParams = true;
      // Construire l'URL de manière dynamique
      let url = `${this.link}/getMoviesFromDynamicSort?limit=${parseInt(localStorage.getItem('limit'))}`;
      if (sort) url += `&sort=${sort}`;
      if (quality) url += `&quality=${quality}`;
      if (category) url += `&category=${category}`;
      if (collection) url += `&collection=${collection}`;
      if (search) url += `&search=${search}`;

      this.films = [];

      // Faire une requête FETCH pour récupérer les films triés en fonction des paramètres de l'URL actuelle
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const moviePromise = await fetch(url, requestOptions).then((res) => res.json());
      const movies = await moviePromise;
      this.films = [];
      movies.forEach(element => {
        element.cat = JSON.parse(element.cat);
        if (element.cat[0] == element.cat[1]) {
          element.cat = element.cat[0];
        } else {
          element.cat = element.cat.join(' & ');
        }
        this.films.push(element);
      });
      this.isInitialLoad = false;
    },
    getAllByCategory: async function getAllByCategory() {
      const categoryBar = document.getElementById('categories');
      const category = categoryBar.value;
      if (category == 'Catégorie') {
        this.deleteURLParam('c');
        return this.getMoviesFromDynamicSort();
      }
      this.addURLParams('c', category, false);
      this.getMoviesFromDynamicSort();
    },
    getAllByCollection: async function getAllByCollection() {
      const collectionBar = document.getElementById('collections');
      const collection = collectionBar.value;
      if (collection == 'Collection') {
        this.deleteURLParam('co');
        return this.getMoviesFromDynamicSort();
      }
      this.addURLParams('co', collection, false);
      this.getMoviesFromDynamicSort();
    },
  },
  created: function () {
    // Vérifier si l'utilisateur est sur mobile
    if (window.innerWidth < 768) this.isUserOnMobile = true;

    const urlParams = new URLSearchParams(window.location.search);
    this.admin();
    this.randomMovie();
    this.getCategories();
    this.getCollections();
    this.getListOfUser();
    this.checkUserEmail();
    this.getWatchedMovies();
    AOS.init();
    // Vérifier si il y a un ou des paramètres de recherche dans l'URL actuelle et récupérer les films en fonction sinon récupérer les films aléatoires
    if (urlParams.has('t') || urlParams.has('q') || urlParams.has('c') || urlParams.has('co') || urlParams.has('s')) return this.getMoviesFromDynamicSort();
    this.getAllRandom();
  },
  mounted: function () {
    setTimeout(() => this.setParamsToSelect(), 1000);
    // Ajoutez un écouteur d'événement de défilement pour mettre à jour scrollDistance
    window.addEventListener('scroll', this.updateScrollDistance);
    // eslint-disable-next-line
    AOS.init();
    setTimeout(() => this.checkerLoading(), this.loadingTime);
    localStorage.removeItem('player');
    localStorage.removeItem('request');
    document.body.style.overflowY = "auto";
    const selectElement = document.querySelector('#triage');
    const qualityBar = document.getElementById('quality');
    qualityBar.addEventListener('change', () => {
      if(qualityBar.value == 'Qualité') {
        this.deleteURLParam('q');
        return this.getMoviesFromDynamicSort();
      };
      this.addURLParams('q', qualityBar.value, false);
      this.getMoviesFromDynamicSort();
    });
    selectElement.addEventListener('change', () => {
      const value = selectElement.value;
      if(value == 'Trier') {
        this.deleteURLParam('t');
        return this.getMoviesFromDynamicSort();
      };
      this.addURLParams('t', value, false);
      this.getMoviesFromDynamicSort();
  });

  },
  beforeUnmount() {
    // Supprimez l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('scroll', this.updateScrollDistance);
  },
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

@keyframes slide {
  from {
    opacity: 0;
    transform: translateZ(-30px);
  }

  to {
    opacity: 1;
    transform: translateZ(0px);
  }
}

@keyframes infiniteSlide {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-70%);
    rotate: -5deg;
  }

  50% {
    transform: translateX(0%);
    rotate: 0deg;
  }

  75% {
    transform: translateX(70%);
    rotate: 5deg;
  }

  100% {
    transform: translateX(0%);
    rotate: 0deg;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slideHorizontal {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(60px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideDown {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(60px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.homePage {
  position: relative;

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $dark-red rgba(255, 255, 255, 0.178);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.178);
  }

  *::-webkit-scrollbar-thumb {
    background-color: $dark-red;
    border-radius: 5px;
    border: 0px none #ffffff;
  }

  width: 100%;
  min-height: 100vh;
  background: $dark-color;
  overflow: none;

  .noResult {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      font-family: 'Lato';
      font-display: swap;
    }
  }

  .loaderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0px;

      button {
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px;
        border-radius: 50px;
        border: none;
        background: rgba(0, 0, 0, 0.1);
        color: white;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }

        i {
          color: $dark-red;
        }
      }
    }
  }

  .noFilmContainer {
    width: 100%;
    padding: 60px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;

    p {
      max-width: 50%;
      color: white;
      font-family: 'Montserrat';
    }
  }

  .random {
    width: 100%;
    height: 90vh;
    overflow: none;
    position: relative;
    z-index: 0;

    .randomContainer {
      height: 100%;
      width: 100%;
      position: relative;

      img {
        &:first-child {
          width: 100%;
          height: 100%;
          filter: brightness(50%) blur(1px);
          object-fit: cover;
        }
      }

      .poster {
        position: absolute;
        bottom: 10%;
        left: 15%;
        z-index: 1;
        color: $light-color;
        display: flex;
        flex-direction: column;
        gap: 5px;

        img {
          width: 330px;
          aspect-ratio: 9/13;
          object-fit: cover;
          filter: blur(0px) brightness(100%);
          border-radius: 10px;
          box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
          transition-duration: 0.4s;

          &:hover {
            transition-duration: 0.4s;
            cursor: pointer;
            filter: brightness(100%);
          }
        }

        .cardLogos {
          position: absolute;
          top: 10px;
          right: 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;

          .trailer {
            svg {
              width: 30px;
              height: 30px;
              fill: $dark-red;
              z-index: 1;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 50%;
              padding: 2px;
              backdrop-filter: blur(5px);
            }
          }

          .hosted {
            svg {
              width: 30px;
              height: 30px;
              fill: $dark-red;
              z-index: 1;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 50%;
              padding: 2px;
              backdrop-filter: blur(5px);

            }
          }
        }

        h4 {
          font-family: 'Lato';
          font-display: swap;
          font-weight: bold;
          font-size: 20px;
          padding: 5px;
        }

        .randomCategory {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          span {
            font-family: 'Montserrat';
            font-display: swap;
            font-size: 16px;
            width: fit-content;
            background: $dark-red;
            padding: 8px 16px;
            border-radius: 50px;
          }
        }

        .randomDuration {
          font-family: 'Montserrat';
          font-display: swap;
          font-weight: bold;
          font-size: 16px;
          padding: 5px;

          .warn {
            color: $dark-red;
          }
        }

        .randomDescription {
          font-family: 'Montserrat';
          font-display: swap;
          font-size: 16px;
          overflow-y: auto;
          max-height: 100px;
          width: 90%;
          padding-right: 20px;
          background: rgba(255, 255, 255, 0.03);
          backdrop-filter: blur(2px);
          border-radius: 10px;
          padding: 5px;
          -webkit-box-shadow: 0px 0px 36px 5px rgba(255, 255, 255, 0.03);
          box-shadow: 0px 0px 36px 5px rgba(255, 255, 255, 0.03);
        }

        .posterInteraction {
          width: 100%;
          padding: 5px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .buttons {
            border: none;
            padding: 5px;
            font-size: 22px;
            color: $light-color;
            background: transparent;
            transition-duration: 0.5s;
            border-bottom: 2px solid transparent;

            &:hover {
              cursor: pointer;
              color: $dark-red;
              border-bottom: 2px solid $dark-red;
              transition-duration: 0.5s;
            }
          }

          .player {
            width: 330px;
            color: $dark-red;
            background: white;
            backdrop-filter: blur(2px);
            padding: 10px 5px;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .playerHeaven {
    width: 70%;
    margin: 50px auto 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    video {
      height: auto;
      margin: 0 auto;
    }

  }

  .orderContainer {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    .order {
      display: flex;
      align-items: center;
      justify-content: start;

      select {
        width: max-content;
      }
    }

    .resetSortContainer {
      
      .resetSort {
        border: none;
        background: transparent;
        color: $dark-red;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        transition-duration: 0.5s;

        &:hover {
          cursor: pointer;
          transition-duration: 0.5s;
        }
      }

      .mobile {
        font-size: 18px;
        text-decoration: underline;
      }
    }

    .layout {
      button {
        border: none;
        font-size: 22px;
        color: black !important;
        background: $light-color !important;
        transition-duration: 0.5s;

        &:hover {
          cursor: pointer;
          color: $dark-red;
          transition-duration: 0.5s;
        }

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .searchBarContainer {
      width: 50%;
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 20px 0 20px 0;

      .searchBar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $light-color;
        gap: 10px;
        padding: 5px 10px;
        border-radius: 20px;

        svg {

          &:hover {
            cursor: pointer;
          }
        }

        input {
          width: 100%;
          outline: none;
          padding: 5px 10px;
          background: transparent;
          border: none;
          border-right: 1px solid $dark-color;
        }
      }
    }
  }

  .displayer {
    width: 100%;
    height: 100vh;
    background: rgba(20, 22, 28, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: none;
  }

  .goTop {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: auto;
    aspect-ratio: 1/1;
    z-index: 10;

    button {
      color: white;
      background: $dark-red;
      padding: 5px;
      border: none;
      border-radius: 5px;
      display: block;
      animation: appear 1s forwards;

      i {
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;

        i {
          animation: bounce 1s infinite;
        }
      }
    }
  }

  .order {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: appear 2s;
    font-family: 'Lato';

    select {
      width: 15%;
      border: none;
      background-color: $dark-red;
      border-radius: 5px;
      color: white;
      padding: 10px;
      outline: none;
      text-indent: 1px;
      text-overflow: '';
      margin: 10px;
      -webkit-appearance: none;

      &:hover {
        cursor: pointer;
      }

      option {
        background: white;
        color: $dark-color;
        padding: 5px 10px;
      }
    }
  }

  .collectionsCarrouselContainer {
    width: 100%;
    max-height: 500px;
    padding: 40px 0 0 0;

    .collectionsCarrousel {
      width: 100%;
      max-height: 500px;
      object-fit: cover;
      //background: #0d0e12;
      padding: 10px 0;

      .collectionsCarrouselCard {
        width: 550px;
        height: 300px;
        object-fit: cover;
        border-radius: 10px;
        transition-duration: 0.5s;
        position: relative;
        border-radius: 15px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 550px;
          height: 300px;
          object-fit: cover;
          z-index: 1;
          filter: brightness(50%);
          border-radius: 15px;
          transition-duration: 0.5s;
        }

        h2 {
          position: absolute;
          bottom: 15px;
          left: 25px;
          color: white;
          font-family: 'Montserrat';
          font-display: swap;
          font-size: 25px;
          z-index: 3;
          transition-duration: 0.5s;
          transform: translateY(50px);
          opacity: 0;
          animation: slideDown 0.5s forwards;
        }

        /* Ajoutez un pseudo-élément ::before pour le box shadow */
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 15px;
          z-index: 2;
          box-shadow: inset 0px 0px 40px 10px rgba(0, 0, 0, 0.81);
        }

        &:hover {
          transition-duration: 0.5s;
          cursor: pointer;

          img {
            transition-duration: 0.5s;
            filter: brightness(1);
          }

          h2 {
            animation: slideUp 0.5s forwards;
            transition-duration: 0.5s;
          }
        }
      }
    }
  }

  .xMasContainer {
    width: 70%;
    margin: auto;

    .xMasButton {
      margin-top: 50px;
      width: 100%;
      height: 150px;
      border-radius: 20px;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

      p {
        max-width: 80%;
        font-family: 'Montserrat';
        font-size: 35px;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0 0 10px rgba(0, 0, 0, 1);
        text-align: center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(30%) blur(3px);
        transition-duration: 0.5s;
      }

      &:hover {
        img {
          filter: brightness(20%) blur(6px);
          transition-duration: 0.5s;
        }
      }
    }
  }

  .watchedSection {
    padding-top: 20px;
    width: 87%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    .watchedSlider {
      width: fit-content !important;
    }
  }

  .filmsSection {
    padding: 60px 0 100px 0;
    width: 90%;
    min-height: 70vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }
}

/* ===== Responsive CSS ===== */

@media screen and (max-width: 1441px) {
  .homePage {
    .orderContainer {
      width: 90%;
    }

    .xMasContainer {
      width: 90%;
    }
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 80vh;
    }

    .orderContainer {
      align-items: center;
      flex-direction: column;

      .order {
        width: fit-content;
        align-items: center;
        flex-wrap: wrap;

        select {
          width: fit-content;
        }
      }

      .searchBarContainer {
        width: 100%;
      }
    }

    .xMasContainer {
      width: 90%;
    }

    .filmsSection {
      width: 95%;
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 80vh;
    }

    .order {
      width: 90% !important;
      align-items: center;
      justify-content: center !important;
      flex-wrap: wrap;

      select {
        width: 50%;
      }
    }

    .xMasContainer {
      width: 90%;
    }

    .filmsSection {
      width: 100%;
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .noFilmContainer {

      p {
        max-width: 80%;
        color: white;
        font-family: 'Montserrat';
      }
    }

    .random {
      .randomContainer {
        img {
          &:first-child {
            filter: brightness(20%);
          }
        }

        .poster {
          bottom: 5%;
          left: 5%;

          img {
            width: 250px;
            filter: brightness(100%);
          }
        }
      }
    }

    .displayer {
      width: 100vw;
      align-items: flex-start;
      background: #15161bc5;
    }

    .orderContainer {

      .order {
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0;
        width: 100% !important;

        select {
          width: auto !important;
        }
      }
    }

    .searchBarContainer {
      order: -1;
      width: 100%;

      .searchBar {
        width: 100%;
      }
    }

    .collectionsCarrouselContainer {


      .collectionsCarrousel {


        .collectionsCarrouselCard {
          width: 350px;
          height: 200px;

          img {
            width: 350px;
            height: 200px;
          }

          h2 {
            font-size: 20px;

          }
        }
      }
    }

    .watchedSection {
      padding-top: 20px;
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;

      .watchedSlider {
        width: fit-content !important;
      }
    }

    .xMasContainer {
      width: 95%;

      .xMasButton {
        height: 100px;

        p {
          font-size: 25px;
        }
      }
    }

    .filmsSection {
      width: 100%;
    }
  }
}
</style>
