<template>
  <div class="mainContainer">
    <NavbarTop />
    <AddMenuNavigation />
    <!--<div class="explanation">
      <h2>Comment ajouter un épisode</h2>
      <ul>
        <li><strong>Fonctionnement des éléments du formulaire :</strong>
          <ul>
            <li><em>Série :</em> Séléctionnez la série à laquelle vous voulez ajouter un épisode.</li>
            <li><em>Image :</em> Ajoutez l'URL de l'image qui correspond à l'épisode, idéalement de Netflix, Prime Video ou The IMDB.</li>
            <li><em>Titre de l'épisode :</em> Entrez le titre officiel de l'épisode en français. Si il n'y a pas de titre autre que "Episode 1", utilisez le bouton "No Name" pour créer le titre sans avoir à le taper.</li>
            <li><em>Description :</em> Fournissez un bref résumé de l'épisode en français si disponible.</li>
            <li><em>Numéro de l'épisode :</em> Entrez le numéro de l'épisode.</li>
            <li><em>Saison :</em> Entrez le numéro de la saison.</li>
            <li><em>Préparer l'épisode suivant :</em> Cochez cette case si vous souhaitez qu'une fois l'épisode ajouté, il prépare le numéro d'épisode suivant et nettoie les champs automatiquement.</li>
            <li><em>Ajouter cet épisode:</em> Appuyer pour ajouter l'épisode enverra les informations au serveur, vous recevrez un message de confirmation.</li>
            <li><em>Préparer l'épisode suivant:</em> Appuyer pour préparer l'épisode suivant nettoiera les champs et définira le champ de l'épisode suivant avec le bon numéro.</li>
            <li><em>Préparer la saison suivante:</em> Appuyer pour préparer la saison suivante nettoiera les champs automatiquement et définira le champs "Saison" par la saison suivante et l'épisode 1.</li>
          </ul>
        </li>
      </ul>
      <h2>Pour ajouter les fichiers, il faudra se rendre sur la page d'édition de la série</h2>
      <p>Vous pouvez y accéder en cliquant sur le bouton "Ajouter les fichiers" une fois l'épisode ajouté.</p>
    </div>-->
    <div class="seriesContainer">
      <select id="serieId" @change="selectSerie">
        <option value="0">Choisir une série</option>
        <option v-for="serie in series" v-bind:key="serie.id" :value="serie.id">{{ serie.name }}</option>
      </select>
    </div>
    <!--Formulaire d'ajout d'un épisode de série -->
    <div class="form" v-if="indexSelection == 1">
      <div class="entry">
        <label for="posterURL">Image </label>
        <input id="posterURL" name="posterURL" type="text" placeholder="http://www.pornhub.com/galery" />
      </div>
      <div class="entry">
        <label for="name">Titre </label>
        <input id="name" name="name" type="text" placeholder="Titre" />
      </div>
      <div class="entry">
        <button @click="setNoName()">No name</button>
      </div>
      <div class="entry">
        <label for="description">Description </label>
        <input id="description" name="question" type="text" placeholder="Description" />
      </div>
      <div class="entry">
        <label for="episode_number">Episode</label>
        <input id="episode_number" name="episode_number" type="number" placeholder="1" value="1"/>
      </div>
      <div class="entry">
        <label for="season">Saison</label>
        <input id="season" name="season" type="number" placeholder="1" value="1"/>
      </div>
      <div class="check">
        <input id="autoNext" name="autoNext" type="checkbox" v-model="isAutoNextEnabled" />
        <label for="autoNext">Préparer l'épisode suivant</label>
        <p><span v-if="!isAutoNextEnabled">Activer</span><span v-else>Désactiver</span> la préparation automatique de l'épisode suivant</p>
      </div>
      <div class="check">
        <input id="autoDesc" name="autoDesc" type="checkbox" v-model="isKeepingDescription" />
        <label for="autoDesc">Garder la description</label>
        <p><span v-if="!isKeepingDescription">Conserver</span><span v-else>Désactiver conservation de</span> la description pour l'épisode suivant</p>
      </div>
      <div class="entry options">
        <button @click="addSerieEpisode()">Ajouter cet épisode</button>
        <button @click="prepareNext()">Préparer l'épisode suivant</button>
        <button @click="prepareNextSeason()">Préparer la saison suivante</button>
        <button @click="goToEditSeriePage()">Ajouter les fichiers</button>
      </div>
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify'
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavbarTop from '../components/NavbarTop.vue'
import AddMenuNavigation from './AddMenuNavigation.vue'
import io from "socket.io-client"

export default {
  name: 'AddMovie',
  components: {
    NavbarTop, AddMenuNavigation
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      indexSelection: 1,
      episodes: 1,
      showCollections: true,
      isLoading: false,
      fileName: undefined,
      loadingMessage: 'Envoi du fichier..',
      step: 0,
      name: undefined,
      series: undefined,
      selectedSerie: undefined,
      isAutoNextEnabled: false,
      isKeepingDescription: false,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    //Aller à la page d'édition de la série du path /edit/serie/:id
    goToEditSeriePage: function goToEditSeriePage() {
      const serie_id = this.selectedSerie;
      this.$router.push('/edit/serie/' + serie_id);
    },
    setNoName: function setNoName() {
      const title = document.getElementById('name');
      title.value = 'Episode ' + document.getElementById('episode_number').value;
    },
    prepareNextSeason: function prepareNextSeason() {
      const episode_poster = document.getElementById('posterURL');
      const title = document.getElementById('name');
      const description = document.getElementById('description');
      const episode_number = document.getElementById('episode_number');
      const season = document.getElementById('season');
      const actualSeason = season.value;
      episode_poster.value = '';
      title.value = '';
      if(!this.isKeepingDescription) description.value = '';
      episode_number.value = 1;
      season.value = (parseInt(actualSeason) + 1);
    },
    prepareNext: function prepareNext(isNoName) {
      const episode_poster = document.getElementById('posterURL');
      const title = document.getElementById('name');
      const description = document.getElementById('description');
      const episode_number = document.getElementById('episode_number');
      episode_poster.value = '';
      if(isNoName) title.value = 'Episode ' + (parseInt(episode_number.value) + 1);
      else title.value = '';
      if(!this.isKeepingDescription) description.value = '';
      episode_number.value = (parseInt(episode_number.value) + 1);
    },
    addSerieEpisode: async function addSerieEpisode() {
      const serie_id = this.selectedSerie;
      if(!serie_id) return this.showNotification('danger', 'Veuillez choisir une série');
      const episode_number = document.getElementById('episode_number').value;
      const episode_poster_url = document.getElementById('posterURL').value;
      const title = document.getElementById('name').value;
      const description = document.getElementById('description').value;
      const season = document.getElementById('season').value;
      const quality = null;
      const link = null;
      const duration = null;
      const credits_start = null;
      const intro_start = null;
      const intro_end = null;
      if (serie_id == 0) return this.showNotification('danger', 'Veuillez choisir une série');
      if (episode_poster_url == '') return this.showNotification('danger', 'Veuillez ajouter une image');
      if (title == '') return this.showNotification('danger', 'Veuillez ajouter un titre');
      if (description == '') return this.showNotification('danger', 'Veuillez ajouter une description');
      if (episode_number == '') return this.showNotification('danger', 'Veuillez ajouter un numéro d\'épisode');
      if (season == '') return this.showNotification('danger', 'Veuillez ajouter un numéro de saison');
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serie_id,
          episode_number,
          episode_poster_url,
          title,
          description,
          season,
          quality,
          link,
          duration,
          credits_start,
          intro_start,
          intro_end
        }),
      };
      await fetch(this.link + '/add/episode', requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.showNotification('success', 'Episode ajouté avec succès');
          if(this.isAutoNextEnabled) this.prepareNext(title.includes('Episode') ? true : false);
        })
    },
    selectSerie: function selectSerie() {
      const selectedSerie = document.getElementById('serieId').value;
      this.selectedSerie = selectedSerie;
    },
    getSeries: async function getSeries() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/allSeriesDisableOrNot', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.series = data;
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          if (data[0].isAdmin == 0) return this.$router.push('/home');
        })
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        showIcon: 'true',
        swipeClose: 'true',
        showCloseButton: 'false',
        hideProgressBar: 'false',
        transition: 'slide',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
  },
  created: function () {
    this.getSeries();
    this.admin();
    // Établissez la connexion WebSocket avec votre serveur
    this.socket = io("https://api.heavenflix.fr")

    // Écoutez l'événement "message" pour recevoir des messages WebSocket
    this.socket.on("message", (message) => {
      this.loadingMessage = message;
      if (message == 'Fichier reçu par le serveur') this.step++;
      if (message == 'Répertoire temporaire créé') this.step++;
      if (message == 'Extraction du fichier') this.step++;
      if (message == 'Conversion des images') this.step++;
      if (message == 'Suppression du fichier d\'origine') this.step++;
      if (message == 'Sauvegarde de la couverture') this.step++;
      if (message == 'Ajout en base de données') this.step++;
    });
  },
  beforeUnmount() {
    // Fermez la connexion WebSocket lorsque le composant est détruit
    if (this.socket) {
      this.socket.close()
    }
  },
  mounted: function () {
    document.body.style.overflowY = "auto";
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

@mixin background-shadow($main-color) {
  background: $main-color;
  box-shadow: 0 0 10px $main-color;
}

.buton {

  border: none;
  padding: 15px;
  border-radius: 50%;

  &:first-child {
    @include background-shadow(dodgerblue);
  }

  &:last-child {
    @include background-shadow(red);
  }
}

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $back-color;

  label {
    margin-left: 5px;
    font-size: 18px;
    font-family: 'Lato';
    color: $light-color;
  }

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);

    .loading {
      background: $dark-color;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      padding: 20px 25px;

      img {
        width: 100px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        margin: 5px 0;
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-size: 14px;
      }
    }
  }

  .selector {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    .selectorContainer {
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      button {
        background: rgba(255, 255, 255, 0.2);
        border: none;
        font-size: 20px;
        font-family: 'Lato';
        color: white;
        padding: 10px 20px;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .explanation {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      color: $light-color;
      font-family: 'Montserrat';
      font-size: 16px;
      margin: 20px 0;
    }

    h2 {
      color: $light-color;
      font-family: 'Lato';
      font-size: 30px;
      margin: 20px 0;
    }

    ul {
      color: $light-color;
      font-family: 'Montserrat';
      font-size: 16px;
      margin: 0 0 20px 0;
      padding: 0 0 0 20px;

      li {
        margin: 10px 0;
      }

      ul {
        margin: 10px 0 0 20px;

        li {
          margin: 5px 0;
        }
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        font-size: 16px;
        margin: 20px 0;
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
        font-weight: bold;
      }

      span {
        font-weight: bold;
      }

      a {
        color: $light-color;
        text-decoration: none;
        font-family: 'Montserrat';
        font-size: 16px;
        transition-duration: 0.4s;

        &:hover {
          color: $dark-red;
          transition-duration: 0.4s;
        }
      }

      button {
        background: $dark-red;
        color: $light-color;
        border: none;
        padding: 10px 20px;
        border-radius: 10px;
        font-family: 'Montserrat';
        font-size: 16px;
        transition-duration: 0.4s;

        &:hover {
          background: $dark-color;
          transition-duration: 0.4s;
        }
      }
    }

  }

  .seriesContainer {
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      margin: 50px 0;
      color: $light-color;
      font-family: 'Lato';
      font-size: 30px;

    }

    #serieId {
      width: 60%;
      height: 40px;
      outline: none;
      border: none;
      padding-left: 10px;
      background: $dark-red;
      color: $light-color;
      font-size: 16px;
      font-family: 'Montserrat';
      border-radius: 10px;

      option {
        background: white;
        color: #090a0e;
        padding: 5px 0;
      }
    }
  }

  .form {
    margin: 10px auto;
    width: 60%;
    padding: 0 0 20px 0;

    .boxes {
      padding: 20px 5px;
      margin-top: 10px;
      background: $soft-grey;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      border-radius: 10px;

      .box {
        list-style: none;
        column-count: 4;

        li {
          display: flex;
          justify-content: space-between;
          padding: 2.5px 10px;
          border-radius: 5px;

          label {
            margin: 0 4px;
            font-family: 'Montserrat';
          }

          input {
            cursor: pointer;
          }

          &:hover {
            transition-duration: 0.4s;
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .options {
      display: flex;
      flex-direction: row !important;
      gap: 10px;
    }

    .check {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin: 10px 0;

      /* Votre CSS existant ici */
      input[type="checkbox"] {
        height: 0;
        width: 0;
        visibility: hidden;
      }

      label {
        cursor: pointer;
        text-indent: -9999px;
        width: 50px;
        height: 25px;
        background: grey;
        display: block;
        border-radius: 100px;
        position: relative;
      }

      label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 15px;
        height: 15px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
      }

      input:checked+label {
        background: $dark-red;
      }

      input:checked+label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
      }

      label:active:after {
        width: 20px;
      }

      label:active {
        background: $dark-red;
      }

      label:active:after {
        width: 20px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        font-size: 16px;

        span {
          font-weight: bold;
        
        }
      }
    }

    .entry {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;

      div {
        margin-top: 20px;

        a {
          width: fit-content;
          background: $dark-color;
          color: $light-color;
          text-decoration: none;
          padding: 10px 20px;
          border-radius: 10px;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Montserrat';

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }
      }

      label {
        margin-bottom: 5px;
        font-size: 20px;
        font-family: 'Lato';
        color: $light-color;
      }

      select {
        width: 100%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      input[type="number"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      .inputFileName {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
          color: $light-color;
          font-family: 'Montserrat';
          font-size: 16px;
        }
      }

      .label-file {
        margin-top: 5px;
        cursor: pointer;
        background: $dark-color;
        width: fit-content;
        padding: 10px 15px;
        border-radius: 10px;
        transition-duration: 0.4s;

        &:hover {
          background: $dark-red;
          transition-duration: 0.4s;
        }
      }

      input[type="file"] {
        display: none;
      }

      button {
        margin: auto;
        width: 50%;
        border: none;
        background: rgb(194, 194, 194);
        color: $back-color;
        padding: 10px 0;
        transition-duration: 0.7s;
        font-size: 16px;
        font-family: 'Lato';
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          transition-duration: 0.7s;
          background: $dark-red;
          color: white;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
          background: $dark-color;
          padding: 10px 15px;
          border-radius: 10px;
          transition: 0.4s;

          a {
            text-decoration: none;
            color: $light-color;
            font-family: 'Montserrat';
          }

          &:hover {
            cursor: pointer;
            background: $dark-red;
            transition: 0.4s;
          }
        }
      }

      &:last-child {
        margin-top: 20px;
      }
    }

    .boxesContainer {
      width: 100%;
      margin-top: 30px;

      .boxesDisplayer {
        width: 98%;
        color: white;
        background: $soft-grey;
        border: none;
        font-size: 17px;
        font-family: 'Lato';
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
        cursor: pointer;
      }
    }
  }
}

//Affichage mobile
@media screen and (max-width: 426px) {
  .mainContainer {
    .form {
      width: 90%;

      .entry {
        input {
          max-width: 98%;
        }
      }

      .boxes {
        padding: 20px 0;

        .box {
          columns: 1;
          width: 100%;

          li {
            margin: auto;
            width: 92%;
            padding: 2.5px 0;
          }
        }
      }

      .boxesContainer {
        .boxesDisplayer {
          width: 92% !important;
        }
      }
    }
  }
}
</style>
